<template>
	<h1 class="title" :style="`padding-left: ${indent ? 20 : 0}px;margin-bottom: ${marginBottom ? 20 : 0}px;    `">
		<slot/>
	</h1>
</template>

<script>
export default {
	props: {
		indent: {
			type: Boolean,
			default: false
		},
		marginBottom: {
			type: Boolean,
			default: false
		}
	}
};
</script>


<style lang="scss" scoped>
.title {
	font-weight: 700;
	color: var(--themeColor);
	display: flex;
	align-items: center;
}

@media screen and (max-width: 736px) {
	.head-title {
		font-size: 25px;
	}
}
</style>
