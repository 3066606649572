const state = {
	toolbox: {
		Magnifier: false,
		Highlighter: false,
		LineReader: false,
		AnswerEliminator: false,
		AnswerMasking: false,
	},
	magnifier_List: {},
	highlighter_List: {},
	lineReader_List: {},
	answerEliminator_List: [],
	answerMasking_List: {},
};

const getters = {
// 	getters_magnifier(state) {
// 		return state.toolbox.Magnifier;
// 	},
// 	getters_highlighter(state) {
// 		return state.toolbox.Highlighter;
// 	},
// 	getters_lineReader(state) {
// 		return state.toolbox.LineReader;
// 	},
// 	getters_answerEliminator(state) {
// 		return state.toolbox.AnswerEliminator;
// 	},
// 	getters_answerMasking(state) {
// 		return state.toolbox.AnswerMasking;
// 	},
//
// 	getters_magnifier_List(state) {
// 		return state.magnifier_List;
// 	},
// 	getters_highlighter_List(state) {
// 		return state.highlighter_List;
// 	},
// 	getters_lineReader_List(state) {
// 		return state.lineReader_List;
// 	},
// 	getters_answerEliminator_List(state) {
// 		return state.answerEliminator_List;
// 	},
// 	getters_answerMasking_List(state) {
// 		return state.answerMasking_List;
// 	},
};

const mutations = {
	set_Magnifier(state, value) {
		state.toolbox.Magnifier = value;
	},
	set_Highlighter(state, value) {
		state.toolbox.Highlighter = value;
	},
	set_LineReader(state, value) {
		state.toolbox.LineReader = value;
	},
	set_AnswerEliminator(state, value) {
		state.toolbox.AnswerEliminator = value;
	},
	set_AnswerMasking(state, value) {
		state.toolbox.AnswerMasking = value;
	},
	set_magnifier_List(state, value) {
		const {key, obj} = value
		state.magnifier_List[key] = obj;
	},
	clear_highlighter_List(state, value) {
		delete state.highlighter_List[value]
	},
	del_highlighter_List(state, value) {
		const {key, id} = value
		if (state.highlighter_List[key]) {
			let copy = JSON.parse(JSON.stringify(state.highlighter_List[key]))
			copy.findIndex((item, index) => {
				if (item) {
					if (item.hs.id === id) {
						copy.splice(index, 1)
					}
				}
			})
			state.highlighter_List[key] = copy
		}
	},
	set_highlighter_List(state, value) {
		const {key, sources} = value
		state.highlighter_List[key] = sources;
	},
	not_first_set_highlighter_List(state, value) {
		const {key, sources} = value
		if (state.highlighter_List[key]) {
			let copy = JSON.parse(JSON.stringify(state.highlighter_List[key]))
			// 如果本次添加的数据是重复的，则略过
			let is_repeat = false
			copy.forEach(item => {
				if (item.hs.id === sources[0].hs.id) {
					is_repeat = true
				}
			})
			if (!is_repeat) {
				state.highlighter_List[key].push(sources[0]);
			}
		}
	},
	set_lineReader_List(state, value) {
		const {key, inner, box} = value
		state.lineReader_List[key] = {
			inner,
			box
		};
	},
	set_answerEliminator_List(state, value) {
		if (state.answerEliminator_List.length) {
			let index = state.answerEliminator_List.indexOf(value)
			if (index >= 0) {
				state.answerEliminator_List.splice(index, 1)
			} else {
				state.answerEliminator_List.push(value);
			}
		} else {
			state.answerEliminator_List = []
			state.answerEliminator_List.push(value);
		}
	},
	set_answerMasking_List(state, value) {
		const {key, masking} = value
		state.answerMasking_List[key] = masking;
	},
};

const actions = {
	set_Magnifier({commit}, value) {
		commit('set_Magnifier', value)
	},
	set_Highlighter({commit}, value) {
		commit('set_Highlighter', value)
	},
	set_LineReader({commit}, value) {
		commit('set_LineReader', value)
	},
	set_AnswerEliminator({commit}, value) {
		commit('set_AnswerEliminator', value)
	},
	set_AnswerMasking({commit}, value) {
		commit('set_AnswerMasking', value)
	},
	set_magnifier_List({commit}, value) {
		commit('set_magnifier_List', value)
	},
	clear_highlighter_List({commit}, value) {
		commit('clear_highlighter_List', value)
	},
	del_highlighter_List({commit}, value) {
		commit('del_highlighter_List', value)
	},
	set_highlighter_List({commit}, value) {
		commit('set_highlighter_List', value)
	},
	not_first_set_highlighter_List({commit}, value) {
		commit('not_first_set_highlighter_List', value)
	},
	// 当前key首次设置行阅读
	set_lineReader_List({commit}, value) {
		commit('set_lineReader_List', value)
	},
	// 当前key首次设置答案遮挡
	first_set_answerMasking_List({commit}, value) {
		const {key, answerTotal, index, status} = value
		let masking = [];
		for (let i = 0; i < answerTotal; i++) {
			if (i == index) {
				masking[i] = status
			} else {
				masking[i] = true
			}
		}
		commit('set_answerMasking_List', {key, masking})
	},
	// 当前key非首次设置答案遮挡
	not_first_set_answerMasking_List({commit}, value) {
		const {key, index, status, masking} = value
		masking[index] = status
		commit('set_answerMasking_List', {key, masking})
	},
};

export default {
	namespaced: true,
	state,
	getters,
	mutations,
	actions
};
