<template>
  <div>
    <div :key="notification.id" v-for="notification in notifications">
      <div
        v-if="
          isNotificationVisible &&
            !ignorePages.includes(routeName) &&
            isBetweenVisibleDuration(notification.start, notification.end)
        "
        :class="`globalNotification ${getPageBackground()}`"
      >
        <div :class="`container ${getClass()}`">
          <el-alert :type="notification.type" :closable="false">
            <slot name="title">
              <div class="notifyContainer">
                <div class="notifyContent">
                  <i class="fas fa-chalkboard-teacher" />
                  <span>{{ notification.description[lang] }} </span>
                  <a
                    v-if="notification.link.url"
                    class="stretchLink"
                    :href="notification.link.url"
                    v-html="notification.link.text[lang]"
                  >
                  </a
                  >{{ period[lang] }}
                </div>
                <el-button
                  class="ml-2"
                  :type="notification.type"
                  size="small"
                  @click="closeNotification"
                >
                  Hide
                </el-button>
              </div>
            </slot>
          </el-alert>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import moment from "moment";
import baseDomainPath from "@/common/baseDomainPath";

export default {
  data() {
    return {
      lastCloseTime: null,
      routeName: ""
    };
  },
  computed: {
    isLogin() {
      return this.$store.getters["user/token"] !== "";
    },
    isLandingPage() {
      return this.routeName === "LandingPage";
    },
    isPlanPage() {
      return this.routeName.includes("ToeflPlans");
    },
    isUserPage() {
      return this.routeName === "Profile";
    },
    isAdminUserPage() {
      return this.routeName === "ProfileForAdmin";
    },
    isResultPDF() {
      return this.routeName === "ResultPDF";
    },
    isRegisterDetailPage() {
      return this.routeName === "RegisterDetail";
    },
    isVerifyPage() {
      return this.routeName === "Verify";
    },
    ignorePages() {
      return [
        "ToeflTest",
        "MToeflTest",
        "FlashcardsVocabulariesCard",
        "MyFlashcardsVocabulariesCard",
        "FlashcardsVocabulariesList",
        "MyFlashcardsVocabulariesList"
      ];
    },
    isNotificationVisible() {
      const closeTime = this.lastCloseTime;
      if (closeTime === null) return true;

      const lastCloseTime = moment(
        moment(closeTime).format("YYYY/MM/DD 23:59:59")
      );
      const now = moment(moment().format("YYYY/MM/DD 00:00:00"));
      if (lastCloseTime.isBefore(moment(now))) {
        return true;
      }

      return false;
    },
    lang() {
      return this.$store.getters["user/langShortValue"];
    },
    period() {
      return {
        tw: "。",
        cn: "。",
        en: "."
      };
    },
    notifications() {
      return [
        {
          id: 3,
          type: "warning",
          start: "2022-02-07 00:00:00",
          end: "2022-02-15 17:00:00",
          description: {
            tw: "Mark your calendar：MOCK 100 的免費線上ACT課#2！",
            cn: "Mark your calendar：MOCK 100 的免费线上ACT课#2！",
            en: "Mark your calendar: FREE ACT Lesson #2 on MOCK 100! "
          },
          link: {
            url: `${baseDomainPath}lessons?v=2`,
            text: {
              tw: "搶先預看 5 分鐘課程",
              cn: "抢先预看 5 分钟课程",
              en: "Click here to watch an extended preview"
            }
          }
        },
        {
          id: 4,
          type: "success",
          start: "2022-02-15 17:00:00",
          end: "2022-02-28 23:59:59",
          description: {
            tw: "上課啦！",
            cn: "上课啦！",
            en: "We are live! "
          },
          link: {
            url: `${baseDomainPath}lessons`,
            text: {
              tw:
                "免費觀看ACT課程#2：\"How to Structure Your ACT Writing Question 1\"",
              cn:
                "免费观看ACT课程#2：\"How to Structure Your ACT Writing Question 1\"",
              en:
                "Click here to watch ACT lesson #2: \"How to Structure Your ACT Writing Question 1\" for FREE"
            }
          }
        },
        {
          id: 5,
          type: "warning",
          start: "2022-04-5 17:00:00",
          end: "2022-04-8 23:59:59",
          description: {
            tw: "公告：MOCK 100 伺服器維修中，僅閱讀單元可正常做題，預計 4/7-4/8 可全站恢復正常，不便之處敬請見諒",
            cn: "公告：MOCK 100 伺服器维修中，仅阅读单元可正常做题，预计 4/7-4/8 可全站恢复正常，不便之处敬请见谅",
            en: "Announcement: We are fixing an issue with our server. As a result, only the reading section can work properly for now. We expect our site to be fully functional by 4/7-4/8. Our sincere apologies for the inconvenience"
          },
          link: {
            url: null,
            text: {
              tw: "",
              cn: "",
              en: ""
            }
          }
        },
        {
          id: 6,
          type: "error",
          start: "2022-11-24 17:00:00",
          end: "2022-11-30 23:59:59",
          description: {
            en: " Black Friday Sale! ",
            cn: " 黑色星期五促销！",
            tw: " 黑色星期五促銷！"
          },
          link: {
            url: `${baseDomainPath}plans`,
            text: {
              en:
                "Enter \"<strong>BLACK20</strong>\" at checkout to get 20% off everything. Offer ends 11/30",
              cn:
                "在结账时输入 \"<strong>BLACK20</strong>\" 所有商品均可享受 20% 的折扣。 优惠于 11 月 30 日结束",
              tw:
                "在結賬時輸入 \"<strong>BLACK20</strong>\" 所有商品均可享受 20% 的折扣。 優惠於 11 月 30 日結束"
            }
          }
        },
        {
          id: 7,
          type: "error",
          start: "2023-08-08 17:00:00",
          end: "2023-08-09 23:59:59",
          description: {
            en: "公告：目前部分地區訪問MOCK 100會出現緩慢的問題，伺服器正在維修，請耐心等候，預計 8/9-8/10 可全站恢復正常，不便之處敬請見諒",
            cn: "公告：目前部分地區訪問MOCK 100會出現緩慢的問題，伺服器正在維修，請耐心等候，預計 8/9-8/10 可全站恢復正常，不便之處敬請見諒",
            tw: "公告：目前部分地區訪問MOCK 100會出現緩慢的問題，伺服器正在維修，請耐心等候，預計 8/9-8/10 可全站恢復正常，不便之處敬請見諒"
          },
          link: {
            url: null,
            text: {
              tw: "",
              cn: "",
              en: ""
            }
          }
        }
      ];
    }
  },
  created() {
    this.setCloseTime();
    if (this.$route.name) {
      this.routeName = this.$route.name;
    } else {
      const unwatch = this.$watch(
        () => this.$route,
        async route => {
          this.routeName = route.name;
          unwatch();
        }
      );
    }
  },
  methods: {
    setCloseTime() {
      this.lastCloseTime = window.localStorage.getItem("notice_close_time");
    },
    closeNotification() {
      const closeTime = moment().format("YYYY/MM/DD");
      window.localStorage.setItem("notice_close_time", closeTime);
      this.lastCloseTime = closeTime;
    },
    isBetweenVisibleDuration(start, end) {
      if (start === undefined || end === undefined) return false;
      const now = moment();
      return now.isBetween(start, end);
    },
    getPageBackground() {
      if (this.isPlanPage) {
        return "planBackground";
      }

      if (this.isResultPDF) {
        return "resultPDFBackground";
      }

      return "";
    },
    getClass() {
      if (this.isPlanPage) {
        return "planContainer";
      }

      if (this.isRegisterDetailPage) {
        return "registerContainer";
      }

      if (this.isVerifyPage) {
        return "verifyContainer";
      }

      if (this.isUserPage) {
        return "userContainer";
      }

      if (this.isAdminUserPage) {
        return "adminUserContainer";
      }

      if (this.isResultPDF) {
        return "resultPDF";
      }

      if (!this.isLogin && this.isLandingPage) {
        return "landingPage";
      }

      return "";
    }
  }
};
</script>

<style lang="scss" scoped>
::v-deep .el-alert__content {
  width: 100%;
}

.notifyContainer {
  display: flex;
  justify-content: space-between;
}

.globalNotification {
  padding-top: 20px;
}

.globalNotification > .container {
  padding-top: 0px;
  margin-bottom: -20px;
}

.notifyContent {
  flex-grow: 1;
  padding: 5px 0px;
  font-size: 14px;
  margin-top: 0px;
  position: relative;
}

.stretchLink::after {
  content: "";
  position: absolute;
  top: 0px;
  bottom: 0px;
  left: 0px;
  right: 0px;
}

.planBackground {
  background: #f4f5f8;
}

.resultPDFBackground {
  background: #f2f3f5;
}

.planContainer {
  margin-bottom: -20px;
  max-width: 100%;
  padding: 0px 30px !important;
}

.registerContainer {
  max-width: 90%;
  padding: 0px;
  margin-bottom: 20px !important;
}

.userContainer {
  max-width: 90%;
  padding: 0px;
  margin-bottom: 0px !important;
}

.adminUserContainer {
  max-width: 95vw;
  padding: 0px 20px;
  margin-bottom: -4px !important;
}

.resultPDF {
  max-width: 1200px;
  margin-bottom: 0px !important;
  padding: 0px !important;
}

.verifyContainer {
  padding: 0px !important;
}

.landingPage {
  max-width: 100%;
  margin-bottom: 0px !important;
  padding: 0px 60px !important;
}
</style>
