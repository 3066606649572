import profileApi from "@/apis/profile";

const state = {
	userRoleProfile: {},
	dataAfterPatch: {},
	dataStudentAfterPatch: {},
	dataTeacherAfterPatch: {},
	childDataFromParent: {},
	childDataAfterPatch: {},
	userDataByAdmin: {},
	userDataByAdminAfterPatch: {}
};

const getters = {
	getDataAfterPatch(state) {
		return state.dataAfterPatch;
	},
	getUserData(state) {
		return state.userRoleProfile;
	},
	getStudentDataAfterPatch(state) {
		return state.dataStudentAfterPatch;
	},
	getTeacherDataAfterPatch(state) {
		return state.dataTeacherAfterPatch;
	},
	getChildDataFromParent(state) {
		return state.childDataFromParent;
	},
	getChildDataAfterPatch(state) {
		return state.childDataAfterPatch;
	},
	getUserDataByAdmin(state) {
		return state.userDataByAdmin;
	},
	getUserDataByAdminAfterPatch(state) {
		return state.userDataByAdminAfterPatch;
	}
};

const mutations = {
	setUserRoleProfile(state, data) {
		state.userRoleProfile = data;
	},
	setDataAfterPatch(state, data) {
		state.dataAfterPatch = data;
	},
	setStudentDataAfterPatch(state, data) {
		state.dataStudentAfterPatch = data;
	},
	setTeacherDataAfterPatch(state, data) {
		state.dataTeacherAfterPatch = data;
	},
	setChildDataFromParent(state, data) {
		state.childDataFromParent = data;
	},
	setChildDataAfterPatch(state, data) {
		state.childDataAfterPatch = data;
	},
	setUserDataByAdmin(state, data) {
		state.userDataByAdmin = data;
	},
	setUserDataByAdminAfterPatch(state, data) {
		state.userDataByAdminAfterPatch = data;
	}
};

const actions = {
	async userRoleProfile({commit}) {
		const data = await profileApi.getUserRoleProfile();
		commit("setUserRoleProfile", data);
	},
	async updateBasicProfile({commit}, payload) {
		commit("setDataAfterPatch", payload);
	},
	async updatePassword({commit}, payload) {
		await profileApi.patchPassword(payload);
	},
	async updateStudentProfile({commit}, payload) {
		const data = await profileApi.patchStudentProfile(payload);
		commit("setStudentDataAfterPatch", data);
	},
	async updateTeacherProfile({commit}, payload) {
		const data = await profileApi.patchTeacherProfile(payload);
		commit("setTeacherDataAfterPatch", data);
	},
	/*eslint-disable no-unused-vars */
	async changePassword({commit}, userId) {
		/*eslint-enable */
		return await profileApi.changePassword(userId);
	},
	async getChildData({commit}, childId) {
		const data = await profileApi.getChildData(childId);
		commit("setChildDataFromParent", data);
	},
	async updateChildProfile({commit}, payload) {
		const {studentId, newProfile} = payload;
		const data = await profileApi.patchChildProfile(studentId, newProfile);
		commit("setChildDataAfterPatch", data);
	},
	// Get user data by Admin
	async getUserDataByAdmin({commit}, userId) {
		const data = await profileApi.getUserData(userId);
		commit("setUserDataByAdmin", data);
	},
	// Get user data by Admin
	async getUserDataForAdminByUserName({commit}, userName) {
		const data = await profileApi.getUserByName(userName);
		commit("setUserDataByAdmin", data);
	},
	// Admin edit basic_info
	async updateBasicProfileByAdmin({commit}, payload) {
		const {id, newProfile} = payload;
		const data = await profileApi.patchBasicProfileByAdmin(id, newProfile);
		commit("setUserDataByAdminAfterPatch", data);
	},
	// Admin edit student
	async updateStudentProfileByAdmin({commit}, payload) {
		const {id, newProfile} = payload;
		const data = await profileApi.patchStudentProfileByAdmin(id, newProfile);
		commit("setUserDataByAdminAfterPatch", data);
	},
	// Admin edit instructor
	async updateTeacherProfileByAdmin({commit}, payload) {
		const {id, newProfile} = payload;
		const data = await profileApi.patchTeacherProfileByAdmin(id, newProfile);
		commit("setUserDataByAdminAfterPatch", data);
	}
};

export default {
	namespaced: true,
	state,
	getters,
	mutations,
	actions
};
