<template>
	<div id="bg">
		<div class="row align-items-center block1">
			<div class="col-md-8 text-left">
				<div class="rights" v-html="rights"></div>
			</div>
			<div class="col-md-4 text-right footer-icon">
				<div class="btnsLeft">
					<!-- Email -->
					<el-tooltip
						class="item"
						placement="top"
						popper-class="tooltipColorFooter"
					>
						<div slot="content">
							<!-- <img
							  src="https://ivy-way-toefl.s3.ap-northeast-1.amazonaws.com/act/img/toefl_email_white.png"
							  alt=""
							  style="display:block;height: 12px;"
							/> -->
							act@mock100.com
						</div>
						<el-button class="btn" circle>
              <span class="icon">
                <i class="fa fa-envelope"></i>
              </span>
							<a
								class="iconLink"
								target="_blank"
								href="mailto:act@mock100.com"
							/>
						</el-button>
					</el-tooltip>

					<!-- <el-tooltip
					  class="item"
					  content="https://twitter.com/bhemock"
					  placement="top"
					  popper-class="tooltipColorFooter"
					>
					  <el-button class="btn" circle>
						<span class="icon">
						  <i class="fab fa-twitter"></i>
						</span>
						<a
						  class="iconLink"
						  target="_blank"
						  href="https://twitter.com/bhemock"
						/>
					  </el-button>
					</el-tooltip> -->
					<!-- Telegram -->
					<!-- <el-tooltip
					  class="item"
					  content="https://t.me/bhemock"
					  placement="top"
					  popper-class="tooltipColorFooter"
					>
					  <el-button class="btn" circle>
						<span class="icon">
						  <i class="fab fa-telegram"></i>
						</span>
						<a
						  class="iconLink"
						  target="_blank"
						  href="https://t.me/bhemock"
						/>
					  </el-button>
					</el-tooltip> -->
					<!-- Youtube -->
					<!-- <el-tooltip
					  class="item"
					  content="https://youtube.com/channel/UC43kI9_Rd4NTgM3MsKkwR9g"
					  placement="top"
					  popper-class="tooltipColorFooter"
					>
					  <el-button class="btn" circle>
						<span class="icon">
						  <i class="fab fa-youtube" />
						</span>
						<a
						  class="iconLink"
						  target="_blank"
						  href="https://youtube.com/channel/UC43kI9_Rd4NTgM3MsKkwR9g"
						/>
					  </el-button>
					</el-tooltip> -->
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import moment from "moment";

export default {
	data() {
		return {
			rights: ""
		};
	},
	mounted() {
		// get current year
		const year = moment().year();
		// this.rights = "© 2022-" + year + " ActMock. All rights reserved. ActMock is not affiliated with or endorsed by ETS® or ACT®.";
		this.rights = `© PrepScholar 2013-${year}. All Rights Reserved.<br>
SAT® is a registered trademark of the College Entrance Examination Board™.<br>
The College Entrance Examination Board™ does not endorse, nor is it affiliated in any way with the owner or any content of this website.ACT® is a registered trademark belonging to ACT Education Corporation ("ACT").<br>
ACT is not involved with or affiliated with PrepScholar Inc, nor does ACT endorse or sponsor any of the products or services offered by PrepScholar Inc.<br>
GRE® and TOEFL® are registered trademarks of the ETS®. The ETS® does not endorse, nor is it affiliated in any way with, the owner or any content of this website.<br>
GMAT® is a registered trademark of the Graduate Management Admissions Council®. The Graduate Management Admissions Council® does not endorse, nor is it affiliated in any way with, the owner or any content of this website.`;
	}
};
</script>

<style lang="scss" scoped>
#bg {
	background-color: var(--themeColor);
	padding: 1.3em 20px 1.3em 20px;
}

.icon {
	display: inline-block;
	width: 18px;
	height: 18px;
	line-height: 18px;
	font-size: 16px;
	color: var(--themeColor);
}

.block1 {
	color: white;
}

.rights {
	line-height: 32px;
}

.block2 {
	display: flex;
	text-align: center;
	text-justify: center;
}

.btnsLeft {
	padding-right: 10px;
}

.btn {
	position: relative;
}

.iconLink {
	position: absolute;
	top: 0px;
	bottom: 0px;
	left: 0px;
	right: 0px;
}

@media screen and (max-width: 768px) {
	.icon {
		display: inline-block;
		width: 16px;
		height: 16px;
		line-height: 16px;
		font-size: 14px;
		color: var(--themeColor);
	}
	.block2 {
		// padding: 0px 120px 0px 120px;
		justify-content: center;
		padding-top: 10px;
		display: flex;
	}
	.btnsLeft {
		padding-right: 10px;
	}
	.footer-icon {
		text-align: center !important;
		margin-top: 10px;
	}
	#bg {
		padding: 10px 15px;
	}
	.rights {
		line-height: 21px;
		font-size: 14px;
	}
}

@media screen and (max-width: 400px) {
	.block2 {
		display: block;
	}
	.btnsLeft {
		padding-right: 0px;
		padding-bottom: 8px;
	}

	.footer-icon {
		margin-top: 0;
	}
}

.input-icon {
	width: 18px;
	height: 18px;
	display: inline-block;
	background-size: cover;
	background-color: var(--themeColor);
}

.icon-email {
	mask: url("../assets/svg/email.svg") no-repeat center;
}

.icon-weibo {
	mask: url("../assets/svg/sina-weibo.svg") no-repeat center;
}

.icon-wechat {
	mask: url("../assets/svg/wechat.svg") no-repeat center;
}

.icon-qq {
	mask: url("../assets/svg/qqchat.svg") no-repeat center;
}

.icon-line {
	mask: url("../assets/svg/line-brands.svg") no-repeat center;
}

.icon-facebook {
	mask: url("../assets/svg/facebook.svg") no-repeat center;
}

.icon-mobile {
	mask: url("../assets/svg/cellphone-iphone.svg") no-repeat center;
}
</style>
