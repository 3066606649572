import JwtService from "@/common/jwt.service";
import userApi from "@/apis/user";
import moment from "moment";
import Package from "@/apis/package";
import { getUrlParams } from '@/utils/tools'

const state = {
    token: "",
    profile: {
        plan_id: -1
    },
    user: {},
    lang: {
        value: "EN-US",
        title: "English"
    },
    isDuplicate: false,
    finishBind: null,
    packageList: [],
};

const getters = {
    async packageList(state) {
        if (state.packageList.length) {
            return new Promise((resolve, reject) => {
                resolve(state.packageList)
            });
        } else {
            let packageInfo = localStorage.getItem('packageInfo')
            if (packageInfo != null && packageInfo != 'false') {
                return new Promise((resolve, reject) => {
                    resolve(JSON.parse(packageInfo))
                });
            } else {
                let URL_DATA = getUrlParams(window.location.href)
                let res = await Package.getPackageInfo({ id: URL_DATA.id });
                localStorage.setItem('packageInfo', JSON.stringify(res))
                return new Promise((resolve, reject) => {
                    resolve(res)
                });
            }
        }
    },
    token(state) {
        if (JwtService.hasToken()) {
            // FIXME: 只能透過 mutation 改變 state
            state.token = JwtService.getToken();
        }

        return state.token;
    },
    langValue(state) {
        return state.lang.value;
    },
    langShortValue(state) {
        const langValue = state.lang.value;
        let lang = "";
        if (langValue === "EN-US") {
            lang = "en";
        }
        if (langValue === "ZH-CN") {
            lang = "cn";
        }
        if (langValue === "ZH-TW") {
            lang = "tw";
        }

        return lang;
    },
    getUser(state) {
        return state.user;
    },
    getProfile(state) {
        return state.profile;
    },
    getIsPro(state) {
        if (Object.keys(state.profile).length) {
            if (state.profile.plan_id) {
                return state.profile.plan_id;
            } else {
                return 0
            }
        } else {
            return 0;
        }
    },
    isIncludeOldPro(state) {
        return (state.profile?.all_roles || []).includes("old-pro");
    },
    getProTimeLimited() {
        return moment((state.profile.starttime + state.profile.duration) * 1000).format('YYYY-MM-DD')
    },
    timezone(state) {
        return state.profile?.timezone;
    },
    roles(state) {
        return state.profile?.all_roles || [];
    },
    isRole: state => role => {
        if (state.profile?.all_roles) {
            return state.profile?.all_roles.includes(role);
        }
        return false;
    },
    isFreeMember(state) {
        const roles = state.profile?.all_roles || [];
        return (
            !roles.includes("super-admin") &&
            !roles.includes("pro") &&
            !roles.includes("old-pro")
        );
    },
    isLogin(state) {
        return state.token !== "";
    }
};

const mutations = {
    setToken(state, token) {
        state.token = token;
        JwtService.saveToken(token);
    },
    setProfile(state, profile) {
        state.profile = profile || false;
    },
    setUser(state, user) {
        state.user = user;
    },
    setLang(state, lang) {
        state.lang = lang;
    },
    setIsDuplicate(state, isDuplicate) {
        state.isDuplicate = isDuplicate;
    },
    resetToken(state) {
        state.token = "";
    },
    setFinishBind(state, finishBind) {
        state.finishBind = finishBind;
    }
};

const actions = {
    async setPackageList({ commit }, chapter_id) {
        let res = await Package.getPackageInfo({ id: chapter_id });
        localStorage.setItem('packageInfo', JSON.stringify(res))
        state.packageList = res
    },
    async register({ commit }, payload) {
        const { username, password, familyname, name, school, grade, phone, address, wechat, verify_id, captcha,code, sn } = payload;
        const data = { username, password, familyname, name, school, grade, phone, address, wechat, verify_id, captcha,code, sn };

        const registerRes = await userApi.register(data);

        if (!registerRes) {
            return false;
        }

        const { token } = registerRes;

        JwtService.saveToken(token);

        commit("setToken", token);
        // commit("setProfile", user);
        return true
    },
    async login({ commit }, payload) {
        const { username, password, verify_id, captcha } = payload;
        const data = { username, password, verify_id, captcha };
        const loginRes = await userApi.login(data);

        if (loginRes) {
            const { token } = loginRes;
            if (!token) {
                return false;
            }
            JwtService.saveToken(token);

            commit("setToken", token);
            // commit("setProfile", user);
            return true
        } else {
            return false
        }
    },
    async logout({ commit }) {
        await userApi.logout();

        JwtService.destroyToken();

        commit("setToken", "");
    },
    async getUser({ commit }) {
        const userRes = await userApi.getUser();
        commit("setUser", userRes);
    },
    async getProfile({ commit }) {
        const profileRes = await userApi.getProfile();
        commit("setProfile", profileRes);
    },
    async checkUsername({ commit }, payload) {
        const { username } = payload;
        const data = { username };

        let isDuplicate = false;

        try {
            await userApi.checkUsernameIsDuplicate(data);

            isDuplicate = false;
        } catch (e) {
            isDuplicate = true;
        }

        commit("setIsDuplicate", isDuplicate);
    },
    async checkFinishBind({ state, commit }) {
        if (state.finishBind === null) {
            //let isBinding = await ApiService.post("finish-bind");

            commit("setFinishBind", true);
        }
    },
    changeLang({ commit }, lang) {
        localStorage.setItem('lang', lang.value)
        commit("setLang", lang);
    },
    resetToken({ commit }) {
        commit("resetToken");
    },
    setToken({ commit }, token) {
        commit("setToken", token);
    }
};

export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions
};
