import Vue from "vue";
import axios from "@/common/interceptors.service";
import VueAxios from "vue-axios";
import JwtService from "@/common/jwt.service";
import {Message} from "element-ui";
import i18n from "@/i18n";

const ENV = process.env.NODE_ENV
const FIRST_TIMEOUT_REMINDER = 20000;
const SERVICE_TIMEOUT_REMINDER = 60000;

const setTimeoutDecorator = apiService => {

	const decorateSetTimeout = func => (async (...args) => {
		let isLoading = true;
		const thisUrl = window.location.href;
		let showingMessage = null;
		setTimeout(() => {
			if (isLoading) {
				showingMessage = Message.warning({
					message: i18n.t("message.internetConnect", {thisUrl}), dangerouslyUseHTMLString: true, showClose: true, duration: 0
				});
			}
		}, FIRST_TIMEOUT_REMINDER);
		let result = null;
		try {
			result = await func(...args);
			if (showingMessage) {
				showingMessage.close();
				Message.success({
					message: i18n.t("message.internetConnectionIsRestored"), dangerouslyUseHTMLString: true, duration: 5000
				});
			}
			isLoading = false;
		} catch (e) {
			isLoading = false;
			throw e;
		}
		return result;
	});

	return {
		...apiService,
		query: decorateSetTimeout(apiService.query),
		img: decorateSetTimeout(apiService.img),
		get: decorateSetTimeout(apiService.get),
		post: decorateSetTimeout(apiService.post),
		update: decorateSetTimeout(apiService.update),
		put: decorateSetTimeout(apiService.put),
		patch: decorateSetTimeout(apiService.patch),
		delete: decorateSetTimeout(apiService.delete)
	};
};

const ApiService = {
	init() {
		Vue.use(VueAxios, axios);
		if (ENV == 'production') {
			// 正式服接口地址
			Vue.axios.defaults.baseURL = 'https://api-act.mock100.com';
		} else {
			// 测试服接口地址
			Vue.axios.defaults.baseURL = 'https://act.ilabi.net';
		}
		Vue.axios.defaults.timeout = SERVICE_TIMEOUT_REMINDER;
		Vue.axios.defaults.withCredentials = true;
	},

	setHeader() {
		Vue.axios.defaults.headers.common["Token"] = `${JwtService.getToken()}`;
	},

	query(resource, params) {
		return Vue.axios.get(resource, {params})
			.then(res => {
				return res
			})
			.catch(error => {
				throw error;
			});
	},
	img(resource, slug = "") {
		return Vue.axios.get(`${resource}${slug}`, {
			responseType: 'blob'
		}).then(res => {
			// 创建FileReader实例
			const reader = new FileReader();

			// 以base64形式读取blob数据
			reader.readAsDataURL(res);

			return new Promise((resolve) => {
				// 设置FileReader的onload事件处理函数，当读取操作完成时会被调用
				reader.onload = e => {
					// 此时e.target.result就是base64字符串
					const base64Data = e.target.result;
					resolve(base64Data)
				};
			})

		}).catch(error => {
			throw error.error;
		});
	}, // img(resource, slug = "") {
	// 	return `${Vue.axios.defaults.baseURL}${resource}${slug}`
	// },
	get(resource, slug = "", params, config) {
		return Vue.axios.get(`${resource}${slug}`, {params, ...config}).then(res => {
			return res
		}).catch(error => {
			return error
		});
	},

	post(resource, params, config) {
		return Vue.axios.post(`${resource}`, params, config).then(res => {
			return res
		}).catch(error => {
			return error
		});
	},

	update(resource, slug, params) {
		return Vue.axios.put(`${resource}/${slug}`, params)
			.then(res => {
				return res
			}).catch(error => {
				return error
			});
	},

	put(resource, params, slug = "") {
		return Vue.axios.put(`${resource}/${slug}`, params)
			.then(res => {
				return res
			}).catch(error => {
				return error
			});
	},

	patch(resource, params, slug = "") {
		return Vue.axios.patch(`${resource}/${slug}`, params)
			.then(res => {
				return res
			}).catch(error => {
				return error
			});
	},

	delete(resource, params, slug = "") {
		return Vue.axios
			.delete(`${resource}/${slug}`, {data: params})
			.catch(error => {
				throw error.error;
			});
	},
};

export default setTimeoutDecorator(ApiService);
