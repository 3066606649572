import Vue from "vue";
import VueRouter from "vue-router";

import JwtService from "@/common/jwt.service";
import store from "@/store";
import roleMixin from "@/mixins/role.js";
import {MessageBox} from "element-ui";
import i18n from "@/i18n";

Vue.use(VueRouter);

const routes = [
	{
		path: "/",
		name: "LandingPage",
		props: true,
		component: () => import("@/views/LandingPage.vue")
	},
	{
		path: "/login",
		name: "Login",
		component: () => import("@/views/Login.vue")
	},
	{
		path: "/password/forgot",
		name: "ForgetPassword",
		component: () => import("@/views/ForgetPassword.vue")
	},
	{
		path: "/password/resetting",
		name: "resettingPassword",
		component: () => import("@/views/resettingPassword.vue")
	},
	{
		path: "/password/set",
		name: "SetPassword",
		component: () => import("@/views/SetPassword.vue")
	},
	{
		path: "/password/reset",
		name: "ResetPassword",
		component: () => import("@/views/ResetPassword.vue")
	},
	{
		path: "/register",
		name: "Register",
		component: () => import("@/views/Login.vue")
	},
	{
		path: "/register-detail",
		name: "RegisterDetail",
		component: () => import("@/views/RegisterDetail.vue"),
		meta: {
			requiresAuth: true,
			skipCheckBind: true
		}
	},
	{
		path: "/profile",
		name: "Profile",
		component: () => import("@/views/user/UserProfile.vue"),
		meta: {
			requiresAuth: true
		}
	},
	{
		path: "/user/:id",
		name: "ProfileForAdmin",
		component: () => import("@/views/user/ProfileForAdminEditUser.vue"),
		props: true,
		meta: {
			requiresAuth: true
		}
	},
	// ===== TOEFL系统 ======
	{
		path: "/toefl",
		name: "PackageList",
		component: () => import("@/views/act/packageList/Index.vue")
	},
	{
		path: "/browse",
		name: "Browse",
		component: () => import("@/views/act/packageList/Browse.vue")
	},
	{
		path: "/systemcheck",
		name: "SystemCheck",
		component: () => import("@/views/SystemCheck.vue")
	},
	{
		path: "/scorereport",
		name: "ToeflScorereport",
		component: () => import("@/views/act/transcript/Scorereport.vue")
	},
	{
		path: "/testresults",
		name: "ToeflTestResults",
		component: () => import("@/views/act/toeflTestResults/Index.vue")
	},
	{
		path: "/testresults/combine",
		name: "ToeflTestResultsCombine",
		component: () => import("@/views/act/toeflTestResults/CombineTests.vue")
	},
	{
		path: "/transcript",
		name: "Transcript",
		component: () => import("@/views/act/transcript/Index.vue")
	},

	{
		path: "/result",
		name: "toeflResult",
		component: () => import("@/views/act/transcript/Index.vue")
	},
	{
		path: "/result/pdf",
		name: "ResultPDF",
		component: () => import("@/views/act/transcript/PDF.vue")
	},
	{
		path: "/gradingsamples",
		name: "Gradingsamples",
		component: () => import("@/views/act/transcript/Example.vue")
	},
	{
		path: "/setTest",
		name: "SetTest",
		component: () => import("@/views/act/actTest/Index.vue")
	},
	{
		path: "/m/setTest",
		name: "MSetTest",
		component: () => import("@/views/act/actTest/mobile/Index.vue")
	},
	{
		path: "/actTest",
		name: "actTest",
		component: () => import("@/views/act/actTest/actTest.vue"),
		meta: {
			showHeader: false,
			showFooter: false
		}
	},
	{
		path: "/actAchievement",
		name: "actAchievement",
		component: () => import("@/views/act/actTest/actAchievement.vue"),
	},
	{
		path: "/actAnswer",
		name: "actAnswer",
		component: () => import("@/views/act/actTest/actAnswer.vue"),
		meta: {
			showHeader: false,
		}
	},
	//Grading
	{
		path: "/report",
		name: "ToeflGrading",
		component: () => import("@/views/act/grading/Index.vue")
	},
	{
		path: "/promotionCode",
		name: "PromotionCode",
		component: () => import("@/views/act/promotionCode/Index.vue")
	},
	//GradingList
	{
		path: "/grading",
		name: "ToeflTestGrading",
		component: () => import("@/views/act/gradingList/Index.vue")
	},
	//Balance
	{
		path: "/balance",
		name: "ToeflBalance",
		component: () => import("@/views/act/balance/Index.vue")
	},
	//Balance
	{
		path: "/profile/balance",
		name: "ToeflProfileBalance",
		component: () => import("@/views/act/balance/Index.vue")
	},
	// Email Verify
	{
		path: "/verify",
		name: "Verify",
		component: () => import("@/views/Verify.vue"),
		meta: {
			skipCheckBind: true
		}
	},
	//Plans, plan 相關頁面開頭都要 ToeflPlans
	{
		path: "/plans",
		name: "ToeflPlans",
		component: () => import("@/views/act/plans/Index.vue")
	},
	{
		path: "/plans/setting",
		name: "ToeflPlansSetting",
		component: () => import("@/views/act/plans/Setting.vue")
	},
	// {
	//   path: "/plans/grading",
	//   name: "ToeflPlansGrading",
	//   component: () => import("@/views/act/plans/Grading.vue")
	// },
	{
		path: "/plans/tcoins",
		name: "ToeflPlansTCoins",
		component: () => import("@/views/act/plans/TCoins.vue")
	},
	//Analytics
	{
		path: "/analytics",
		name: "ToeflAnalytics",
		component: () => import("@/views/act/analytics/Index.vue"),
		meta: {
			isAdminPage: true
		}
	},
	{
		path: "/payments",
		name: "ToeflPayments",
		component: () => import("@/views/act/payments/Index.vue"),
		meta: {
			isAdminPage: true
		}
	},
	{
		path: "/students",
		name: "ToeflStudents",
		component: () => import("@/views/act/students/Index.vue"),
		meta: {
			isAdminPage: true
		}
	},
	{
		path: "/flashcards",
		name: "Flashcards",
		component: () => import("@/views/act/flashcards/Index.vue")
	},
	{
		path: "/myflashcards",
		name: "MyFlashcards",
		component: () => import("@/views/act/flashcards/Index.vue")
	},
	{
		path: "/flashcards/vocabularies/list",
		name: "FlashcardsVocabulariesList",
		props: true,
		component: () => import("@/views/act/flashcards/VocabulariesList.vue"),
		meta: {
			showHeader: false,
			showFooter: false
		},
		beforeEnter(to, from, next) {
			store.commit("flashcard/initVocabList");
			next();
		}
	},
	{
		path: "/flashcards/vocabularies/card",
		name: "FlashcardsVocabulariesCard",
		props: true,
		component: () => import("@/views/act/flashcards/VocabulariesCards.vue"),
		meta: {
			showHeader: false,
			showFooter: false
		},
		beforeEnter(to, from, next) {
			store.commit("flashcard/initVocabList");
			next();
		}
	},
	{
		path: "/flashcards/myvocabularies/list",
		name: "MyFlashcardsVocabulariesList",
		props: true,
		component: () => import("@/views/act/flashcards/VocabulariesList.vue"),
		meta: {
			showHeader: false,
			showFooter: false
		},
		beforeEnter(to, from, next) {
			store.commit("flashcard/initVocabList");
			next();
		}
	},
	{
		path: "/flashcards/myvocabularies/card",
		name: "MyFlashcardsVocabulariesCard",
		props: true,
		component: () => import("@/views/act/flashcards/VocabulariesCards.vue"),
		meta: {
			showHeader: false,
			showFooter: false
		},
		beforeEnter(to, from, next) {
			store.commit("flashcard/initVocabList");
			next();
		}
	},
	{
		path: "/lessons",
		name: "Lessons",
		component: () => import("@/views/act/lessons/Index.vue")
	},
	{
		path: "/lessons",
		name: "Lesson",
		component: () => import("@/views/act/lessons/Index.vue")
	},
	{
		path: "/lesson/create",
		name: "CreateLessons",
		component: () => import("@/views/act/lessons/Create.vue")
	},
	{
		path: "/lesson/edit",
		name: "EditLessons",
		component: () => import("@/views/act/lessons/Edit.vue")
	},
	{
		path: "/materials",
		name: "MyMaterials",
		component: () => import("@/views/act/materials/Index.vue")
	},
	{
		path: "/materials/all",
		name: "Materials",
		component: () => import("@/views/act/materials/Index.vue")
	},
	// TODO: 404
	{
		path: "/404",
		name: "PageNotFound",
		component: () => import("@/views/PageNotFound.vue")
	},
	{
		path: "*",
		name: "RedirectToNotFound",
		component: () => import("@/views/RedirectToNotFound.vue")
	}
];
const router = new VueRouter({
	mode: "history",
	base: process.env.NODE_ENV === "development" ? process.env.BASE_URL : process.env.BASE_URL,
	routes,
	// 導向新的 router 會到新頁面的 top
	scrollBehavior() {
		return {x: 0, y: 0};
	}
});
router.beforeEach(async (to, from, next) => {
	const ignoreRemeberPage = [
		"Login",
		"Register",
		"PageNotFound",
		"ForgetPassword",
		"SetPassword",
		"ResetPassword",
		"Verify"
	];
	if (!ignoreRemeberPage.includes(to.name)) {
		localStorage.setItem("lastAccessRoutePath", to.fullPath);
	}
	if (to.meta.requiresAuth && !JwtService.hasToken()) {
		next({name: "Login"});
	}

	if (!to.meta.skipCheckBind && JwtService.hasToken()) {
		await store.dispatch("user/checkFinishBind");
		if (!store.state.user.finishBind) {
			next({name: "RegisterDetail"});
		}
		await store.dispatch("user/getUser");

		// Check Role Is Admin
		if (to.meta.isAdminPage && !roleMixin.methods.isRoleAdmin()) {
			next({name: "PageNotFound"});
		}
	}

	// clear previous order
	if (
		from.name !== "Login" &&
		["ToeflProfileBalance", "ToeflBalance", "ToeflPlansTCoins"].includes(to.name)
	) {
		window.localStorage.removeItem("PREVIOUS_ORDER");
	}
	next();
});

router.onError(error => {
	const pattern = "Loading chunk";
	const isChunkLoadingFailed = error.message.includes(pattern);
	const cssPattern = "Loading CSS chunk";
	const isCSSChunkLoadingFailed = error.message.includes(cssPattern);
	if (isChunkLoadingFailed || isCSSChunkLoadingFailed) {
		MessageBox({
			title: i18n.t("message.notice"),
			message: `
        ${i18n.t("message.chunkLoadingError1")}<a target="_blank" href="mailto:act@mock100.com">
        ${i18n.t("message.chunkLoadingError2")}</a>${i18n.t("message.chunkLoadingError3")}
      `,
			confirmButtonText: i18n.t("alert.refresh"),
			showClose: false,
			closeOnClickModal: false,
			closeOnPressEscape: false,
			closeOnHashChange: false,
			dangerouslyUseHTMLString: true,
			callback: () => window.location.reload()
		});
	}
});

export default router;
