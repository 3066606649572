const ID_TOKEN_KEY = "id_token";

export const getToken = () => {
    return hasToken() ? window.localStorage.getItem(ID_TOKEN_KEY) : "";
};

export const saveToken = token => {
    window.localStorage.setItem(ID_TOKEN_KEY, token);
};

export const destroyToken = () => {
    window.localStorage.removeItem(ID_TOKEN_KEY);
};

export const hasToken = () => {
    return window.localStorage.getItem(ID_TOKEN_KEY) !== null && window.localStorage.getItem(ID_TOKEN_KEY) !== "undefined";
};

export default { getToken, saveToken, destroyToken, hasToken };
