<template>
    <el-container>
        <div id="feedback" v-if="!getUser.admin">
            <el-tooltip class="item" effect="dark" content="Feedback" placement="top">
                <span class="contact-help text-warning" @click="goToFeedbackPage">
                    <i class="fas fa-bug"></i>
                </span>
            </el-tooltip>
        </div>
        <el-header v-if="showHeader" v-show="!getUser.admin">
            <Header />
        </el-header>
        <el-main :key="`${$route.fullPath}Page`" :class="isIpad ? 'ipad' : ''">
            <GlobalNotification :key="$route.fullPath" />
            <div v-if="existAdBlock" class="container">
                <PageTitle>
                    {{ getSimpleTitle(this.$route.name) }}
                </PageTitle>
            </div>
            <router-view v-else />
        </el-main>
        <Footer v-if="showFooter" v-show="!getUser.admin" />
        <el-backtop class="phone" :right="15" :bottom="40"></el-backtop>
        <el-backtop class="pc" :right="40" :bottom="100"></el-backtop>

    </el-container>
</template>

<script>
require("@/assets/js/all.min.js");
require("@/assets/js/bootstrap.bundle.min.js");
require("@/assets/js/jquery-3.5.1.slim.min.js");

import { user } from "@ivy-way/material";
/*eslint-disable no-unused-vars */
import { mapActions, mapState, mapGetters } from "vuex";
/*eslint-enable */
import PageTitle from "@/components/PageTitle.vue";
import Footer from "@/components/Footer";
import Header from "@/components/Header";
import GlobalNotification from "@/views/GlobalNotification";
import { getUrlParams } from "@/utils/tools";

export default {
    metaInfo() {
        return {
            title: "MOCK 100"
        };
    },
    components: {
        PageTitle,
        Footer,
        Header,
        GlobalNotification
    },

    mixins: [],

    props: [],
    data() {
        return {
            countryCode: "",
            existAdBlock: false
        };
    },
    computed: {
        ...mapState("user", ["lang", "profile"]),
        ...mapGetters("user", ["getUser"]),
        isIpad() {
            let isIpad = false;
            if (navigator.userAgent.indexOf("iPad") != -1) {
                isIpad = true;
            }
            return isIpad;
        },
        showLink() {
            let url = "https://blog.ivy-way.com/toefl-cn/";
            if (this.lang.value === "ZH-CN") {
                url = url + "?lang=cn";
            } else if (this.lang.value === "EN-US") {
                url = url + "?lang=en";
            }
            return url;
        },
        showHeader() {
            if (this.$route.meta.showHeader === undefined) {
                return true;
            } else {
                return false;
            }
        },
        showFooter() {
            if (this.$route.meta.showFooter === undefined) {
                return true;
            } else {
                return false;
            }
        },
        isIgnoreAdPages() {
            const ignoreAdPages = ["ToeflTest", "MToeflTest", "ToeflPlans"];
            if (this.$route.name) {
                return ignoreAdPages.includes(this.$route.name);
            } else {
                const unwatch = this.$watch(
                    () => this.$route,
                    async route => {
                        unwatch();
                        return ignoreAdPages.includes(route);
                    }
                );
            }
            return true;
        },
        isLocalhost() {
            return window.location.href.includes("http://localhost:");
        },
        isLogin() {
            return this.$store.getters["user/token"] !== "";
        },
        isPro() {
            return this.$store.getters["user/getIsPro"];
        }
    },
    async created() {
        let url_param = getUrlParams(window.location.href);
        if (url_param.token) {
            this.setToken(url_param.token);
            await this.$store.dispatch("user/getUser");
        } else {
            if (this.$store.getters["user/token"]) {
                await this.$store.dispatch("user/getUser");
                if (this.getUser.admin) {
                    this.logout();
                }
            }

        }

        if (this.isLogin) {
            // await this.$store.dispatch("user/getProfile");
        }
    },

    methods: {
        ...mapActions("user", ["setToken", "logout"]),
        async goToFeedbackPage() {
            window.open("mailto:act@mock100.com", "_blank");
        },
        foundAdBlockResult(result) {
            this.existAdBlock = result;
        },
        getSimpleTitle(routeName) {
            if (routeName === "Profile") {
                return user.displayName(
                    this.profile.first_name,
                    this.profile.last_name
                );
            }

            const simpleTitleLocalKeyMap = {
                LandingPage: "package.index.title_new",
                ToeflTestResults: "package.index.title_new",
                ToeflPlans: "header.plans",
                Flashcards: "header.flashcards",
                MyFlashcards: "flashcards.myFlashCards",
                Lessons: "pageTitle.lessons",
                ToeflProfileBalance: "toefl.Balance",
                ToeflScorereport: "pageTitle.scoreReport",
                toeflResult: "pageTitle.ToeflTestResults",
                ResultPDF: "pageTitle.ToeflTestResults",
                Gradingsamples: "GradingSamples.TOEFL Writing Grading",
                ToeflResolve: "pageTitle.explanation"
            };
            return this.$t(
                simpleTitleLocalKeyMap[routeName] || simpleTitleLocalKeyMap.LandingPage
            );
        }
    }
};
</script>
<style>
@import "@/assets/css/all.min";
@import "@/assets/css/bootstrap.min";
</style>
<style lang="scss">
@import "@/assets/scss/style";



.el-header {
    box-shadow: 0 5px 5px #aaa;
    background-color: white;
    position: fixed;
    width: 100%;
    z-index: 1001;
    padding: 0;
    margin: 0;
}

header.el-header {
    padding: 0;
    margin: 0;
}

.pc {
    display: flex !important;
}

.phone {
    display: none !important;
}


main.el-main {
    min-height: calc(100vh - 74px);
    padding: 0;
    padding-top: 100px;
}

.container {
    padding-top: 40px;
}

@media screen and (max-width: 992px) {
    main.el-main {
        padding-top: 60px;
    }
}

.toefl-title {
    font-size: 20px;
    font-weight: 700;
    margin-bottom: 25px;
}

.toefl-welcome {
    text-align: center;
    margin-top: 15px;
    margin-bottom: 15px;
}
</style>
<style scoped>
::v-deep #feedback .el-dialog__body {
    padding: 5px 40px;
}

::v-deep #feedback .el-alert {
    padding: 0px 16px;
}

.contact-help {
    right: 40px;
    bottom: 160px;
    position: fixed;
    background-color: #ffffff;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    color: var(--themeColor);
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 20px;
    box-shadow: 0 0 6px rgb(0 0 0 / 12%);
    cursor: pointer;
    z-index: 100;
}

::v-deep .el-alert .el-alert__description {
    margin-top: 0;
}

::v-deep .el-upload-dragger {
    width: 450px;
    height: 200px;
}

@media screen and (max-width: 768px) {
    .phone {
        display: flex !important;
    }

    .pc {
        display: none !important;
    }

    ::v-deep .el-dialog--center .el-dialog__body {
        padding: 5px 20px;
    }

    ::v-deep .el-form--label-left .el-form-item__label {
        display: block;
        width: 100%;
        line-height: normal;
    }

    ::v-deep .el-upload-dragger {
        width: 100%;
        height: 150px;
    }

    ::v-deep .el-upload-dragger .el-icon-upload {
        margin: 20px 0 16px;
    }

    ::v-deep .el-upload {
        width: 100%;
    }

    /* ::v-deep .el-form-item {
    margin-bottom: 12px;
  } */
    .contact-help {
        bottom: 100px;
        right: 15px;
    }

    ::v-deep #feedback .el-dialog__body {
        padding: 5px 15px;
    }

    ::v-deep.el-button+.el-button {
        margin-left: 0;
    }
}
</style>
