import ApiService from "@/common/api.service";

export default {
	getPromotionCodeRecords() {
		return ApiService.query("promotion-code-records");
	},
	findPasswordCode(data) {
		return ApiService.get("/addons/act/user/forget_password", '', data);
	},
	resetPassword(data) {
		return ApiService.post("/addons/act/user/reset_password", data);
	},
	register(data) {
		return ApiService.post("/addons/act/user/register", data, {withCredentials: true});
	},
	login(data) {
		return ApiService.post("/addons/act/user/login", data);
	},
	getUser() {
		return ApiService.post("/addons/act/user/user_info");
	},
	getProfile() {
		return ApiService.post("/addons/act/user/plan");
	},
	logout() {
		return ApiService.post("/addons/act/user/logout");
	},
	checkUsernameIsDuplicate(data) {
		return ApiService.post("user/check_username", data);
	},
	searchStudents(data) {
		return ApiService.query("students", data);
	},
	searchParents(data) {
		return ApiService.query("parent/search", data);
	},
	// getCaptcha() {
	//   return ApiService.query("/captcha/flat");
	// },
	getCaptcha(data) {
		return ApiService.img(`/addons/act/user/captcha?verify_id=`, data);
	},
	getStudentTestAnalysis(data) {
		return ApiService.query("student/test_analysis", data);
	},
	getStudentByParentId(parentId) {
		return ApiService.query(`parent/${parentId}/children`);
	},
	saveLang(data) {
		return ApiService.post("/addons/act/user/set_language", data);
	},
	verifyEmail(data) {
		return ApiService.query("/verify", data);
	},
	getReferralCode() {
		return ApiService.query("/referral_code");
	},
	checkReferralCode(data) {
		return ApiService.post("/referral_code/check", data);
	},
	getReferralUsed() {
		return ApiService.query("/referral_code/used ");
	},
	sendVerifyEmail() {
		return ApiService.post("/send-verify-email");
	},
	exchangePoint() {
		return ApiService.post("/referral_code/exchange-point");
	},
	deleteUser(user_id) {
		return ApiService.delete("account/destroy/" + user_id);
	},
	getUsers(data) {
		return ApiService.query("admin/user", data);
	},
	updateUserProExpiredDate(userId, expiredDate) {
		return ApiService.patch(`admin/user/${userId}/pro`, {
			taken_unlimited_expire_at: expiredDate
		});
	},
	giveTCoin(userId, tCoin) {
		return ApiService.post(`admin/user/${userId}/give-point`, {
			point: tCoin
		});
	},
	deductTCoin(userId, tCoin) {
		return ApiService.post(`admin/user/${userId}/deduct-point`, {
			point: tCoin
		});
	},
	removePro(userId) {
		return ApiService.delete(`admin/user/${userId}/pro`);
	},
	switchBlockStatus(userId) {
		return ApiService.post(`admin/user/${userId}/toggle-block`);
	},
	switchProStatusPermission(userId) {
		return ApiService.post(`admin/user/${userId}/toggle-old-pro`);
	},
	patchSetting(data) {
		return ApiService.patch("toefl-settings", data);
	},
	getSetting() {
		return {}//ApiService.get("toefl-settings");
	}
};
