import ApiService from "@/common/api.service";

export default {
    getPackageClassList(data) {
        return ApiService.get("/addons/act/package/package_classic_list", '', data);
    },
    getPackage(data) {
        return ApiService.get("/addons/act/package/package_list", '', data);
    },
    getPackageInfo(data) {
        return ApiService.get("/addons/act/package/package_info", '', data);
    },
    getPackageStart(data) {
        return ApiService.post("/addons/act/package/start", data);
    },
    getPackageStartSubject(data) {
        return ApiService.post("/addons/act/package/start_subject", data);
    },
    getPackageExistExam(data) {
        return ApiService.get("/addons/act/package/exist_exam", '', data);
    },
    getPackageGetItem(data) {
        return ApiService.get("/addons/act/package/get_item", '', data);
    },
    getPackageGetFlag(data) {
        return ApiService.get("/addons/act/package/get_flag", '', data);
    },
    getPackageFlag(data) {
        return ApiService.post("/addons/act/package/flag", data);
    },
    getPackageAnswer(data) {
        return ApiService.post("/addons/act/package/answer", data);
    },
    getPackageEndSubject(data) {
        return ApiService.post("/addons/act/package/end_subject", data);
    },
    getPackageEnd(data) {
        return ApiService.post("/addons/act/package/end", data);
    },
    getExamInfo(data) {
        return ApiService.get("/addons/act/package/exam_info", '', data);
    },
    getWallet() {
        return ApiService.get("/addons/act/user/wallet");
    },
    getToefls(data) {
        return ApiService.query("toefls", data);
    },
    getToeflTags() {
        return ApiService.query("toefls/tags");
    },
    getExamId(data) {
        return ApiService.post("toefls/get_exam_id", data);
    },
    getUnfinishedExam(exam_id) {
        return ApiService.get(`toefls`, 'has_unfinished_exam', { exam_id });
    },
    getQuestions(exam_id, data) {
        return ApiService.get("toefls", "questions", { ...data, exam_id });
    },
    getAnswers(data) {
        return ApiService.get("toefls", 'answers', { user_exam_id: data });
    },
    updateAnswers(id, data) {
        return ApiService.update("toefls/answer/user_exam_id", id, data);
    },
    uploadAudio(data) {
        return ApiService.post("document", data);
    },
    deleteAudio(id) {
        return ApiService.delete(`document/${id}`);
    },
    getTranscript(user_exam_id) {
        return ApiService.get("toefls/transcript/id", user_exam_id);
    },
    getResolve(user_exam_id, data) {
        return ApiService.get("toefls/question_exp/id", user_exam_id, data);
    },
    getBrowse(toefl_chapter_id) {
        return ApiService.get("/toefl-editor/chapters", toefl_chapter_id);
    },
    getAllTranscripts(data) {
        return ApiService.query("addons/act/package/exam_list", data);
    },
    gradeScore(user_exam_answer_id, data) {
        return ApiService.update("toefls/grade", user_exam_answer_id, data);
    },
    updateScore(id) {
        return ApiService.update("toefls/update-score", id);
    },
    openPackages() {
        return ApiService.query("packages");
    },
    createOrder(data) {
        return ApiService.post("order", data);
    },
    getTransactions() {
        return ApiService.query("point_transactions");
    },
    getTransactionsForcorrect(data) {
        return ApiService.query("/toefls/transcripts-for-correct", data);
    },
    getPointPackage(id) {
        return ApiService.query("/toefls/pointPackage/id/" + id);
    },
    payForPackage(id, data) {
        return ApiService.post("/exam/pay_for_point_package/id/" + id, data);
    },
    updateTranscript(id, data) {
        return ApiService.update("/toefls/grade", id, data);
    },
    getNotification(data) {
        return ApiService.query("/toefls/not-score-notification/", data);
    },
    getExamIdByToeflId(toeflId, category) {
        return ApiService.query(`toefls/transcripts/combine/${toeflId}`, { category });
    },
    getTests(data) {
        return ApiService.query("toefls/transcripts/combine", data);
    },
    combineTests(data) {
        return ApiService.post("toefls/transcripts/combine", data);
    },
    sendEmail(userExam) {
        return ApiService.post(`/toefls/corrected/${userExam}`);
    },
    fetchStudents(keyword) {
        return ApiService.query("toefls/transcripts/taken-exam-users", { keyword });
    },
    getWriting() {
        return ApiService.query("dev/toefl-testing");
    },
    getContent(id) {
        return ApiService.query("dev/toefl-all-except-writing?tpo=" + id);
    },
    postFeedback(id, data) {
        return ApiService.post(`exam/${id}/refund-point`, data);
    },
    payForPDF(id) {
        return ApiService.post(`exam/${id}/pay-for-print-pdf`);
    },
    payByUnlockNumberForPDF(id) {
        return ApiService.post(`exam/${id}/exchange-print-pdf`);
    },
    viewPdf(id) {
        return ApiService.query(`toefls/transcript/${id}/print`);
    },
    getExamPrintPdfIsUnlock(id) {
        return ApiService.get(`exam/${id}/unlock-print-pdf-status`);
    },
    checkFiles(id) {
        return ApiService.get(`toefls/audio_urls`, id, { exam_id: id });
    },
    getQuestionCount() {
        return ApiService.get("toefl-question-count");
    },
    editToefl(id, data) {
        return ApiService.update("/toefl-editor/", id, data);
    },
    editChapter(id, data) {
        return ApiService.update("/toefl-editor/chapter", id, data);
    },
    editQuestion(id, data) {
        return ApiService.update("/toefl-editor/question", id, data);
    },
    editOption(id, data) {
        return ApiService.update("/toefl-editor/option", id, data);
    },
    editAnswer(id, data) {
        return ApiService.update("/toefl-editor/answer", id, data);
    },
    editExplanation(id, data) {
        return ApiService.update("/toefl-editor/explanation", id, data);
    },
    getAllTags(data) {
        return ApiService.query("/toefl-editor/tags", data);
    },
    getAllToeflTitle(userId) {
        return ApiService.query("toefls/transcripts_toefls", { user_id: userId });
    },
    updateTags(id, data) {
        return ApiService.patch(`/toefl-editor/question/${id}/tags`, data);
    },
    getSubScoreCategories(skillType) {
        return ApiService.query("/toefls/grade/comment-category", { type: skillType });
    }
};
