import Vue from "vue";

import App from "@/App.vue";

import router from "@/router";
import store from "@/store";

import i18n from "@/i18n";
import htmlToPdf from "@/mixins/htmlToPdf.js";
import ApiService from "@/common/api.service";
import ElementUI from "@/plugin/elementui.js";
import locale from "element-ui/lib/locale/lang/en";
import $ from 'jquery';
import 'jquery-ui/ui/widgets/resizable';
import 'jquery-ui/ui/widgets/draggable';
import 'jquery-ui/themes/base/all.css';
/*
  一些 hidden-尺寸 的 class
  https://element.eleme.io/#/en-US/component/layout#utility-classes-for-hiding-elements
*/
import "@/assets/scss/ivy-element/index.scss";

// 主要是 helper class，沒有全加
import "@/assets/scss/ivy-bootstrap.scss";

import VueMeta from "vue-meta";
// 加入 CKEditor
import CKEditor from "ckeditor4-vue";

Vue.use(htmlToPdf);

ApiService.init();
Vue.use(ElementUI, {locale});

Vue.use(VueMeta);

Vue.use(CKEditor);

Vue.mixin({
	methods: {
		routerPush(route) {
			this.$router.push(route);
		},
		historyBack() {
			this.$router.go(-1);
		}
	}
});

//主題色的參數
var themeColor = "#42a16a";
Vue.config.productionTip = false;
Vue.prototype.themeColor = themeColor;
Vue.prototype.$ = $;
Vue.prototype.recorderGet = null;

// if(JwtService.hasToken()){
//   if(store.state.user.user === null){
//       store.dispatch('user/getUser').then({})
//   }
// }

new Vue({
	router,
	store,
	i18n,
	render: h => h(App)
}).$mount("#app");
