import Vue from "vue";
import Vuex from "vuex";
import createPersistedState from 'vuex-persistedstate';
import user from "./user.module";
import registerDetails from "./registerDetails.module";
import captcha from "./captcha.module";
import profile from "./profile.module";
import payment from "./payment.module";
import analytics from "./analytics.module";
import flashcard from "./flashcard.module";
import tools from "./tools.module";

Vue.use(Vuex);

export default new Vuex.Store({
	modules: {
		user,
		registerDetails,
		captcha,
		// ipGeo,
		profile,
		// password,
		payment,
		analytics,
		flashcard,
		tools
	},
	plugins: [
		createPersistedState({
			storage: window.localStorage,
			reducer(val) {
				return {
					tools: val.tools
				}
			}
		})
	]
});
