export function getUrlParams(url) {
	const searchParams = new URLSearchParams(url.split('?')[1]);
	const params = {};

	for (const [key, value] of searchParams.entries()) {
		params[key] = value;
	}

	return params;
}
